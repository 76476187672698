<template>
  <div class="page-container places-page">
    <portal to="page-name">Locais</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Locais</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Novo local
            </button>
          </div>
        </div>
        <div class="loading mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Locais</p>
              <p class="empty-subtitle">
                Nenhum local encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th class="hide-sm" width="40px">#</th>
            <th>Nome</th>
            <th class="text-left">Tipo</th>
            <th class="hide-sm hide-md text-right">Ativo</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="hide-sm">{{ i + data.offset + 1 }}</td>
            <td><router-link :to="editLink(item)">{{ item.name }}
            </router-link></td>
            <td class="text-left">{{ placeType.getName(item.type) }}</td>
            <td class="hide-sm hide-md text-right">{{ item.active ? 'Sim' : 'Não' }}</td>
            <td class="text-right">
              <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip"
                      @click="edit(item)" data-tooltip="Editar">
                <fa-icon :icon="['fal', 'pencil']"></fa-icon>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
          </template>
        </template>
      </div>
      <div class="card-footer" v-if="data.items.length > 0">
        <st-pagination :total="data.total"
                       :limit="data.limit" @paginate="paginate"></st-pagination>
        <span>Total de registros:
          <span class="ml-2 text-primary text-bold">{{ data.total }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import * as placeType from 'src/data/place-types';

export default {
  data() {
    return {
      path: '/places',
      debounceTimeout: null,
      loading: false,
      placeType,
      filter: {
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.search, 600);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      this.data.offset = 0;
      this.load();
    },
    load() {
      this.loading = true;

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    create() {
      this.$router.push(`${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`${this.path}/${item.id}/edit`);
    },
    editLink(item) {
      return `${this.path}/${item.id}/edit`;
    },
    paginate({ offset }) {
      this.data.offset = offset;
      this.load();
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .places-page {
  }
</style>
