export const places = {
  warehouse: 'Almoxarifado',
  surgery_center: 'Centro Cirúrgico',
  clinical: 'Clínico',
  hospitalization: 'Unidade de Internação',
};

export function getName(value) {
  return places[value];
}
