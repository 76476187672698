<template>
  <div class="page-container place-page">
    <portal to="page-name">Cadastro de local</portal>
    <div class="card card-page">
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Cadastro de local</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-8 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @change="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
          <div class="column col-4 col-md-6 col-sm-12 form-group">
            <label for="type" class="form-label">Tipo</label>
            <select id="type" name="type" class="form-select"
                    v-model="form.type">
              <option v-for="(text, value) in placeType.places"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
        </div>
        <template v-if="form.id && hasBed">
          <div class="empty mb-2" v-if="form.beds.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Leitos</p>
            <p class="empty-subtitle">
              Nenhum leito cadastrado. Utilize o botão abaixo para realizar o cadastro.
            </p>
            <button class="btn btn-primary btn-icon-left" @click="openBedModal">
              <fa-icon :icon="['fal', 'plus']"></fa-icon>
              Novo leito
            </button>
          </div>
          <template v-else>
            <div class="divider" />
            <button class="btn btn-primary btn-icon-left" @click="openBedModal">
              <fa-icon :icon="['fal', 'plus']"></fa-icon>
              Novo leito
            </button>
            <table class="table table-hover">
              <thead>
              <tr>
                <th>#</th>
                <th>Leito</th>
                <th>Acomodação</th>
                <th style="width: 40px"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in form.beds" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  <span class="hover-link" @click="editBed(item.id, i)">
                    {{ item.name }}
                  </span>
                </td>
                <td>{{ item.accommodationType ? item.accommodationType.name : '-' }}</td>
                <td>
                  <button class="btn btn-sm btn-action btn-icon btn-primary"
                          :class="{loading: bed.loading && bed.idx === i}"
                          :disabled="bed.loading && bed.idx === i"
                          @click="editBed(item.id, i)">
                    <fa-icon :icon="['fal', 'pencil']"></fa-icon>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </template>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
    <dx-modal title="Cadastro de leito" id="modal-bed" v-model="bed.show">
      <div class="columns">
        <div class="column col-8 col-md-12 col-sm-12 form-group"
             :class="{'has-error': $v.bed.form.name.$error}">
          <label for="name" class="form-label">Nome</label>
          <input type="text" id="bed-name" class="form-input" placeholder="Nome do leito"
                 v-model="bed.form.name" @change="$v.bed.form.name.$touch()">
          <template v-if="$v.bed.form.name.$error">
            <div class="form-input-hint"
                 v-if="!$v.bed.form.name.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-4 col-md-12 col-sm-12 form-group"
             :class="{'has-error': $v.bed.form.accommodationType.id.$error}">
          <label for="accommodation-type" class="form-label">Acomodação</label>
          <select id="accommodation-type" class="form-select"
                  v-model="bed.form.accommodationType.id"
                  @blur="$v.bed.form.accommodationType.id.$touch()">
            <option value="">[Selecione]</option>
            <option v-for="(item, i) in bed.accommodationTypes"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
          <template v-if="$v.bed.form.accommodationType.id.$error">
            <div class="form-input-hint"
                 v-if="!$v.bed.form.accommodationType.id.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :class="{loading: bed.saving}"
                :disabled="bed.saving"
                @click="saveBed">
          Salvar
        </button>
        <button class="btn" @click="closeBedModal">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';
import * as placeType from 'src/data/place-types';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/places',
      placeType,
      isNew: true,
      loading: false,
      saving: false,
      bed: {
        show: false,
        loading: false,
        saving: false,
        idx: null,
        accommodationTypes: [],
        form: this.blankBedForm(),
      },
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  computed: {
    hasBed() {
      return this.form.type === 'surgery_center' || this.form.type === 'hospitalization';
    },
  },
  validations() {
    return {
      bed: {
        form: {
          name: { required },
          accommodationType: {
            id: { required },
          },
        },
      },
      form: {
        name: { required },
        active: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadAccommodationTypes() {
      return this.$http
        .get('/accommodation-types')
        .then(({ data }) => {
          this.bed.accommodationTypes = data.items;
        })
        .catch(() => {});
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http
        .get(this.path, { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('Local já adicionado', { type: 'error' });
        return;
      }

      this.saving = true;

      const place = this.clone(this.form);

      const request = this.isNew
        ? this.$http.post(this.path, place)
        : this.$http.put(`${this.path}/${this.form.id}`, place);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    editBed(id, i) {
      this.bed.loading = true;
      this.bed.show = true;

      this.bed.idx = i;

      if (this.bed.accommodationTypes.length === 0) {
        this.loadAccommodationTypes();
      }
      this.$v.bed.form.$reset();

      return this.$http
        .get(`/beds/${id}`)
        .then(({ data }) => {
          this.bed.form = mergeFrom(this.blankBedForm(), data);
        })
        .catch(() => {})
        .then(() => {
          this.bed.loading = false;
        });
    },
    saveBed() {
      this.$v.bed.form.$touch();
      if (this.$v.bed.form.$error) {
        return;
      }

      this.bed.saving = true;

      const bedData = this.clone(this.bed.form);
      bedData.accommodationTypeId = this.bed.form.accommodationType.id;

      const request = this.bed.form.id
        ? this.$http.put(`/beds/${this.bed.form.id}`, bedData)
        : this.$http.post(`${this.path}/${this.form.id}/beds`, bedData);

      // eslint-disable-next-line consistent-return
      return request
        .then(() => {
          this.closeBedModal();
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.bed.saving = false;
        });
    },
    openBedModal() {
      this.$v.bed.form.$reset();
      if (this.bed.accommodationTypes.length === 0) {
        this.loadAccommodationTypes();
      }
      this.bed.form = this.blankBedForm();
      this.bed.show = true;
    },
    closeBedModal() {
      this.load();
      this.bed.show = false;
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: 'clinical',
        active: true,
        beds: [],
      };
    },
    blankBedForm() {
      return {
        id: '',
        name: '',
        accommodationType: {
          id: '',
          name: '',
        },
      };
    },
  },
};
</script>

<style lang="scss">
  .place-page {
  }
</style>
